if (!Element.prototype.matches) {
Element.prototype.matches = Element.prototype.msMatchesSelector || 
							Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
Element.prototype.closest = function(s) {
	var el = this;

	do {
	if (el.matches(s)) return el;
	el = el.parentElement || el.parentNode;
	} while (el !== null && el.nodeType === 1);
	return null;
};
}

import { initMobileNav } from './header'
import { initHomeCaro, initHomeMap } from './home';
import { contactSubmit } from './contact.js';

initMobileNav();

if(document.querySelector('#home_caro')) {
	initHomeCaro();
}

if(document.querySelector('#map_canvas')) {
	initHomeMap();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}
