import { AutomatitCarousel } from './automatit_carousel.m';
import { getMapsHelper } from  './util/mapsHelper'

export const initHomeCaro = () => {
    const homeCaroImgs = [
        {src: "/static/images/availableselfstorage-new01.jpg", alt: ""},
        {src: "/static/images/availableselfstorage-new02.jpg", alt: ""},
        {src: "/static/images/availableselfstorage-new03.jpg", alt: ""},
        {src: "/static/images/availableselfstorage-new04.jpg", alt: ""},
        {src: "/static/images/availableselfstorage-new05.jpg", alt: ""},
    ];

    AutomatitCarousel({
        element: document.querySelector('#home_caro'),
        images: homeCaroImgs,
        imagesAsBackgrounds: true,
        showDots: true,
        useChevrons: false,
    })
}

export const initHomeMap = () => { 
    const mapHelper = getMapsHelper();

    mapHelper.ready()
        .then(() => {
            const theMap = mapHelper.createMap({
                element: document.querySelector('#map_canvas'),
                locationElementSelector: '#map_canvas',
            })

            theMap.addListener('idle', () => {
                theMap.setZoom(15);
            })
        })
}