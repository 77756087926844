import { setState, toggleState } from './util/stateHelper';

export const initMobileNav = () => {
    const navs = document.querySelectorAll('nav');
    document.querySelector('#pull').addEventListener('click', e => {
        e.preventDefault();

        navs.forEach(i => {
            toggleState(i, 'closed', 'open');
        })
    })
}